.view_reg {
  &__text-bold {
    display: block;
    padding: 0 0.7rem;
    @include pc {
      font-size: 1.1rem;
      padding: 0 4rem;
    }
  }

  &__error {
    font-size: 1.6rem;
    padding-top: 3rem;
    color: rgb(248, 21, 21);
    font-weight: bold;
  }

  form {
    margin: 0 1.5rem;
    @media (max-width: 650px) {
      margin: 0 1rem;
    }
  }

  &__btnbox {
    vertical-align: top;
    max-width: 39rem;
    margin: 0 auto;
    padding-bottom: 2rem;
    @media (max-width: 690px) {
      margin: 0.3rem 1.2rem;
    }
    @media (max-width: 500px) {
      text-align: left;
      padding-bottom: 1rem;
    }
  }

  &__addform {
    float: right;
    @media (max-width: 500px) {
      float: none;
      display: block;
      margin-bottom: 1.5rem;
      margin-top: -0.5rem;
    }
    width: 13rem;
    padding: 0.5rem 0 0.4rem 0;
    border: 1px solid gray;
    border-radius: 0.6rem;
    font-size: 0.9rem;
    background-color: rgba(255, 255, 255, 0.87);
    color: #597c58;
    cursor: pointer;
  }

  &__submit {
    float: left;
    @media (max-width: 500px) {
      float: none;
      display: block;
    }
    width: 13rem;
    padding: 0.5rem 0 0.4rem 0;
    border: none;
    border-radius: 0.6rem;
    font-size: 0.9rem;
    text-align: center;
    color: white;
  }

  &__submit-true {
    background-color: rgba(194, 166, 6, 0.836);
    cursor: pointer;
    -webkit-appearance: none;
  }
  &__submit-false {
    background-color: rgb(173, 173, 173);
    cursor: no-drop;
  }

  &__saved {
    margin-top: 3rem;
  }
}

.view_admin {
  padding: 1rem;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;

  @include pc {
    display: block;
    background-color: white;
    padding: 1rem;
    width: calc(100vw - 4rem);
    max-width: 90rem;
    margin: 0 auto;
  }

  @media (max-width: 849px) and (min-height: 400px) {
    min-height: 90vh;
  }

  &__clickable {
    text-decoration: underline;
    &:hover {
      cursor: pointer;
      @include pc {
        background-color: rgb(255, 253, 220);
      }
    }
  }

  &__stats {
    margin-bottom: 1rem;
    padding-left: 5rem;
    b {
      font-size: 1.2rem;
    }
  }

  &__tablecontainer {
    overflow-x: scroll;
  }
  table {
    width: 90rem;
    border-collapse: collapse;
    margin-bottom: 1rem;
  }
  table,
  th,
  td {
    padding: 0.3rem 0.3rem;
    border: 1px solid goldenrod;
  }

  // make the text in th have underline
  th {
    position: relative;
    svg {
      position: absolute;
      top: 0.4rem;
      right: 0.2rem;
    }
  }

  &__sendemail {
    min-width: 2rem;
  }
  &__namne {
    min-width: 12rem;
  }
  &__email {
    min-width: 13rem;
  }
  &__phone {
    min-width: 8rem;
  }
  &__party {
    width: 3.4rem;
    padding-right: 1.4rem !important;
    padding-left: 0.4rem !important;
  }
  &__speach {
    width: 3.4rem;
    padding-right: 1.4rem !important;
    padding-left: 0.4rem !important;
  }
  &__food {
    width: 6.2rem;
    padding-right: 1.4rem !important;
    padding-left: 0.4rem !important;
  }
  &__added {
    min-width: 9rem;
  }
  &__code {
    min-width: 7rem;
  }
  &__msg {
    min-width: 35rem;
  }

  table > thead > tr {
    background-color: rgb(245, 223, 102);
    text-align: left;
  }
  table > tbody > tr:nth-child(even) {
    background-color: rgb(230, 230, 230);
  }
  table > tbody > tr > td:nth-child(1),
  table > tbody > tr > td:nth-child(5),
  table > tbody > tr > td:nth-child(6),
  table > tbody > tr > td:nth-child(7) {
    text-align: center;
  }
}

.view_road {
  padding-bottom: 3rem;
  ul,
  li {
    text-align: left;
    margin-left: 1.1rem;
    margin-right: 0.5rem;
    @include pc {
      font-size: 1.1rem;
      margin-left: 3rem;
    }
  }
  ul {
    list-style: none;
  }
  ul li::before {
    content: "\2022"; /* Add content: \2022 is the CSS Code/unicode for a bullet */
    color: var(--main-color); /* Change the color */
    font-weight: bold; /* If you want it to be bold */
    font-size: 1.3rem;
    display: inline-block; /* Needed to add space between the bullet and the text */
    width: 1rem; /* Also needed for space (tweak if needed) */
    margin-left: -1rem; /* Also needed for space (tweak if needed) */
  }
  &__imgdiv {
    position: relative;
    display: inline-block;
    padding: 0 1rem;
    &:hover {
      opacity: 0.7;
      cursor: pointer;
    }
    img {
      width: 100%;
      max-width: 35rem;
      border: 0.15rem solid var(--main-color);
    }
    p {
      position: absolute;
      background-color: rgba(255, 255, 255, 0.925);
      bottom: 0.7rem;
      right: 1.4rem;
      color: black;
      padding: 0 0.3rem;
      font-weight: bold;
      font-size: 0.9rem;
    }
  }
}

.view_welcome {
  p {
    max-width: 43rem;
    margin: 0 auto;
  }
  &__menuinfo {
    display: block;
    margin: 1rem 0;
    @include pc {
      display: none;
    }
  }
}

.view_info {
  b {
    font-size: 1.1rem;
  }
  &__important {
    font-weight: normal;
    background-color: rgba(204, 174, 5, 0.418);
    margin: 1rem;
    padding: 0.75rem 0;

    &__br {
      height: 0.4rem;
    }
  }
}

.view_acco {
  b {
    font-size: 1.2rem;
  }
}

.view_married {
  h2 {
    margin: 2rem 0 1rem 0;
  }
  p {
    max-width: 43rem;
    margin: 0 auto;
    padding: 0.5rem 1rem;
  }
  &__menuinfo {
    display: block;
    margin: 1rem 0;
    @include pc {
      display: none;
    }
  }

  .img_container_mar {
    border-radius: 0.5rem;
    margin: 1rem auto;
    width: calc(100% - (1.5rem * 2));
    max-width: 20rem;
    height: 30rem;
    @media screen and (min-width: 470px) {
      max-width: 30rem;
      height: 38rem;
    }
    @media screen and (min-width: 600px) {
      max-width: 33rem;
      height: 50rem;
      p {
        font-size: 1.2rem;
      }
    }

    background-repeat: no-repeat;
    background-size: cover;
    box-shadow: 0rem 0rem 1rem 0rem rgb(100, 100, 100);
  }
}
