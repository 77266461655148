@charset "UTF-8";
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --main-color: rgb(204, 174, 5);
  --hamburger-color: #739471;
  --background-color: rgb(255, 255, 255);
}

html {
  scroll-behavior: smooth;
  min-height: 100vh;
}
@media (min-width: 850px) and (min-height: 400px) {
  html {
    padding-top: 2rem;
  }
}

body {
  font-family: "Cormorant";
  overflow-x: clip;
  background-color: var(--background-color);
}
@media (min-width: 850px) and (min-height: 400px) {
  body {
    background-position: 50% 50%;
    background-image: url("../images/pc-bg.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
  }
}

#root {
  position: relative;
  max-width: 50rem;
  margin: auto;
}

a {
  text-decoration: none;
}

main {
  padding: 6rem 0.5rem 5.5rem 0.5rem;
  position: relative;
  text-align: center;
  z-index: 9;
}
main p {
  padding: 0.75rem 1rem;
  line-height: 1.5rem;
  font-size: 1rem;
}
main h1 {
  color: rgb(54, 54, 54);
  letter-spacing: 0.15rem;
  font-size: 1.9rem;
}
main h2 {
  font-size: 1.4rem;
}
main h3 {
  padding-top: 1.2rem;
}
@media (max-width: 849px) and (min-height: 400px) {
  main {
    min-height: 90vh;
  }
}
@media (min-width: 850px) and (min-height: 400px) {
  main {
    min-height: 40rem;
    box-shadow: 0rem 0rem 2rem 0rem rgb(92, 92, 92);
    margin-bottom: 2rem;
    padding-bottom: 4rem;
  }
  main p {
    font-size: 1.1rem;
  }
  main h1 {
    font-size: 2.1rem;
  }
  main h2 {
    font-size: 1.5rem;
  }
  main h3 {
    font-size: 1.2rem;
  }
}

@media (min-width: 850px) and (min-height: 400px) {
  .comp_hamburger {
    display: none;
  }
}
.comp_hamburger__lines {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  position: absolute;
  z-index: 11;
  top: 0;
  right: 0;
  transition: margin 230ms ease;
  padding: 1.25rem 0.75rem 1rem 1rem;
}
.comp_hamburger__line1, .comp_hamburger__line2, .comp_hamburger__line3 {
  transition: opacity 1ms, background-color 300ms, transform 300ms, margin 300ms;
  width: 2.3rem;
  height: 0.25rem;
  border-radius: 1rem;
  background-color: var(--hamburger-color);
}
.comp_hamburger__line2 {
  margin: 0.32rem 0;
}

.comp_nav {
  position: fixed;
  z-index: 10;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(24, 94, 14, 0.94);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  transition: opacity 200ms;
}
@media (min-width: 850px) and (min-height: 400px) {
  .comp_nav {
    display: none;
  }
}
.comp_nav__inner a {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}
.comp_nav__inner a li {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  list-style: none;
  color: white;
  font-size: 1.4rem;
  letter-spacing: 0.07rem;
  padding: 0.5rem 0;
  margin-bottom: 1rem;
}
@media (max-height: 400px) {
  .comp_nav__inner a li {
    margin-bottom: 0rem;
  }
}

.comp_navpc {
  display: none;
  margin-left: 4rem;
  position: absolute;
  top: 1.6rem;
  right: 6rem;
}
@media (min-width: 850px) and (min-height: 400px) {
  .comp_navpc {
    display: block;
  }
}
.comp_navpc__inner a {
  -webkit-tap-highlight-color: rgba(131, 30, 30, 0);
}
.comp_navpc__inner a li {
  display: inline-block;
  margin: 0.15rem 1.5rem;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  list-style: none;
  color: rgb(187, 160, 6);
  font-size: 1rem;
  letter-spacing: 0.04rem;
}

.comp_countdown {
  margin-top: 0.5rem;
}
.comp_countdown__box {
  display: inline-block;
  width: 4.5rem;
}
.comp_countdown__num {
  font-size: 2rem;
}
.comp_countdown__text {
  font-size: 0.8rem;
}

.comp_regform {
  --input-bg: rgba(211, 211, 211, 0.432);
  --border-bg: rgba(141, 141, 141, 0.877);
  max-width: 39rem;
  margin: 2rem auto;
  padding: 0 0.5rem 1rem 0.5rem;
  background-color: rgba(255, 255, 255, 0.842);
  border-radius: 0.6rem;
  box-shadow: 0 0 0.5rem 0.2rem rgb(228, 228, 228);
  transition: max-height 700ms ease-in;
  overflow: hidden;
}
.comp_regform > p {
  padding-top: 0.7rem;
  padding-bottom: 0.5rem;
}
@media (min-width: 850px) and (min-height: 400px) {
  .comp_regform label {
    font-size: 1.1rem;
  }
}
.comp_regform__fbox {
  display: inline-block;
  margin: 0.3rem 0.5rem;
  max-width: 16.5rem;
  width: 100%;
  text-align: left;
}
@media (max-width: 650px) {
  .comp_regform__fbox {
    width: 100%;
    max-width: 22rem;
    margin: 0.3rem 0;
    padding: 0 0.5rem;
  }
}
.comp_regform input[type=text],
.comp_regform input[type=email],
.comp_regform input[type=tel] {
  width: 100%;
  margin-top: 0.1rem;
  padding: 0.6rem;
  border: none;
  outline: none;
  background-color: var(--input-bg);
}
.comp_regform input[type=text]:focus-visible,
.comp_regform input[type=email]:focus-visible,
.comp_regform input[type=tel]:focus-visible {
  padding: 0.45rem 0.45rem;
  border: 0.15rem solid var(--border-bg);
}
.comp_regform__fdivider {
  height: 0.15rem;
  margin: 0.5rem 0;
  background-color: rgb(225, 225, 225);
}
.comp_regform__fbox_choice {
  display: inline-block;
}
.comp_regform__fbox_choice > p {
  padding: 0.3rem 0;
}
.comp_regform__flabel-choice {
  display: inline-block;
  margin: 0 0.7rem 0.2rem 0.7rem;
  padding: 0 1rem;
}
.comp_regform__flabel-choice input {
  margin-right: 0.3rem;
}
.comp_regform__fbox-textarea {
  display: inline-block;
  margin: 0 auto;
  max-width: 30rem;
  width: 87%;
  margin-top: 0.3rem;
}
.comp_regform__fbox-textarea textarea {
  display: block;
  margin-top: 0.3rem;
  height: 6rem;
  width: 100%;
  padding: 0.3rem;
  resize: none;
  outline: none;
  border: none;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  background-color: var(--input-bg);
}
.comp_regform__fbox-textarea textarea:focus-visible {
  border: 0.15rem solid var(--border-bg);
  padding: 0.2rem;
}

.comp_presents {
  text-align: left;
  padding: 0 1rem;
}
.comp_presents h3 {
  padding: 0;
  font-size: 1.1rem;
}
.comp_presents p {
  padding: 0;
}
.comp_presents__item {
  margin-bottom: 2rem;
  padding: 0.5rem 0 0.5rem 0.5rem;
  background-color: white;
  box-shadow: 0 0 0.5rem rgb(180, 180, 180);
  border-radius: 0.5rem;
}
.comp_presents__item__img {
  text-align: center;
  margin: 1rem 0 0.5rem 0;
}
.comp_presents__item__img img {
  max-height: 11rem;
}
@media (max-width: 400px) {
  .comp_presents__item__img img {
    max-width: 9rem;
  }
}
.comp_presents__item__link {
  display: inline-block;
  margin-top: 0.6rem;
  padding: 0.2rem 0.4rem;
  background-color: rgb(238, 238, 238);
  border-radius: 0.2rem;
  font-size: 0.9rem;
}
.comp_presents__item__link a {
  color: rgb(1, 19, 177);
}
.comp_presents__item button {
  width: 15rem;
  width: calc(100% - 0.5rem);
  margin-top: 0.8rem;
  margin-bottom: 0.3rem;
  background-color: white;
  padding: 0.4rem 0;
  border-radius: 0.6rem;
  color: var(--hamburger-color);
  border: 1px solid var(--hamburger-color);
}
.comp_presents__item button:hover {
  cursor: pointer;
  background-color: var(--hamburger-color);
  color: white;
}
.comp_presents__modal {
  position: fixed;
  z-index: 13;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
}
.comp_presents__modal_content {
  position: fixed;
  z-index: 14;
  top: 0;
  right: 0;
  left: 0;
  width: calc(100% - 2rem);
  max-width: 40rem;
  margin: calc(40vh - 2.5rem) auto 0 auto;
  padding: 1rem;
  background-color: white;
}
.comp_presents__modal_content h2 {
  margin-bottom: 0.1rem;
  font-size: 1rem;
}
@media (min-width: 850px) and (min-height: 400px) {
  .comp_presents__modal_content h2 {
    font-size: 1.3rem;
  }
}
.comp_presents__modal_content input {
  margin-top: 0.5rem;
  padding: 0.3rem 0.5rem;
  border-radius: 0.3rem;
  border: 1px solid gray;
}
.comp_presents__modal_content__btnreserve {
  margin: 1rem 0;
  background-color: var(--main-color);
  color: white;
}
.comp_presents__modal_content__btnreserve:disabled {
  background-color: rgb(204, 204, 204);
  color: rgb(243, 243, 243);
}
.comp_presents__modal_content__btncancle {
  background-color: rgb(204, 109, 109);
  color: white;
}
.comp_presents__modal_content button {
  display: block;
  font-weight: bold;
  border: none;
  padding: 0.3rem 0;
  width: 7.5rem;
  border-radius: 0.4rem;
}
.comp_presents__modal_content button:hover:not(:disabled) {
  cursor: pointer;
}
.comp_presents__editbtn {
  margin: 1rem 0.6rem 2rem 0.6rem;
  padding: 0.3rem 0.5rem;
  color: var(--hamburger-color);
  font-weight: bold;
  background-color: white;
  border: 1px solid var(--hamburger-color);
  border-radius: 0.5rem;
}
.comp_presents__editbtn:hover {
  cursor: pointer;
}
@media (min-width: 850px) and (min-height: 400px) {
  .comp_presents__editbtn:hover {
    color: white;
    background-color: var(--hamburger-color);
  }
}
.comp_presents__edit {
  padding-bottom: 1rem;
  border: 1px solid var(--hamburger-color);
  border-radius: 1rem;
  margin: 1rem 1rem 1rem 1rem;
}
@media (min-width: 850px) and (min-height: 400px) {
  .comp_presents__edit {
    border: none;
  }
}
.comp_presents__edit input {
  padding: 0.2rem 0.4rem;
  margin-top: 0.3rem;
  margin-bottom: 0.4rem;
}
.comp_presents__edit h2 {
  font-size: 1.3rem;
  margin-bottom: 1.5rem;
}
@media (min-width: 850px) and (min-height: 400px) {
  .comp_presents__edit h2 {
    font-size: 1.4rem;
  }
}
.comp_presents__edit div {
  margin: 0.7rem auto;
}
.comp_presents__edit div > span {
  font-weight: bold;
}
.comp_presents__edit div > button {
  margin-left: 1rem;
  background-color: rgb(247, 181, 58);
  border: none;
  padding: 0.3rem 0.5rem;
  border-radius: 0.5rem;
}
.comp_presents__edit div > button:hover {
  cursor: pointer;
  color: white;
}

.view_reg__text-bold {
  display: block;
  padding: 0 0.7rem;
}
@media (min-width: 850px) and (min-height: 400px) {
  .view_reg__text-bold {
    font-size: 1.1rem;
    padding: 0 4rem;
  }
}
.view_reg__error {
  font-size: 1.6rem;
  padding-top: 3rem;
  color: rgb(248, 21, 21);
  font-weight: bold;
}
.view_reg form {
  margin: 0 1.5rem;
}
@media (max-width: 650px) {
  .view_reg form {
    margin: 0 1rem;
  }
}
.view_reg__btnbox {
  vertical-align: top;
  max-width: 39rem;
  margin: 0 auto;
  padding-bottom: 2rem;
}
@media (max-width: 690px) {
  .view_reg__btnbox {
    margin: 0.3rem 1.2rem;
  }
}
@media (max-width: 500px) {
  .view_reg__btnbox {
    text-align: left;
    padding-bottom: 1rem;
  }
}
.view_reg__addform {
  float: right;
  width: 13rem;
  padding: 0.5rem 0 0.4rem 0;
  border: 1px solid gray;
  border-radius: 0.6rem;
  font-size: 0.9rem;
  background-color: rgba(255, 255, 255, 0.87);
  color: #597c58;
  cursor: pointer;
}
@media (max-width: 500px) {
  .view_reg__addform {
    float: none;
    display: block;
    margin-bottom: 1.5rem;
    margin-top: -0.5rem;
  }
}
.view_reg__submit {
  float: left;
  width: 13rem;
  padding: 0.5rem 0 0.4rem 0;
  border: none;
  border-radius: 0.6rem;
  font-size: 0.9rem;
  text-align: center;
  color: white;
}
@media (max-width: 500px) {
  .view_reg__submit {
    float: none;
    display: block;
  }
}
.view_reg__submit-true {
  background-color: rgba(194, 166, 6, 0.836);
  cursor: pointer;
  -webkit-appearance: none;
}
.view_reg__submit-false {
  background-color: rgb(173, 173, 173);
  cursor: no-drop;
}
.view_reg__saved {
  margin-top: 3rem;
}

.view_admin {
  padding: 1rem;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
@media (min-width: 850px) and (min-height: 400px) {
  .view_admin {
    display: block;
    background-color: white;
    padding: 1rem;
    width: calc(100vw - 4rem);
    max-width: 90rem;
    margin: 0 auto;
  }
}
@media (max-width: 849px) and (min-height: 400px) {
  .view_admin {
    min-height: 90vh;
  }
}
.view_admin__clickable {
  text-decoration: underline;
}
.view_admin__clickable:hover {
  cursor: pointer;
}
@media (min-width: 850px) and (min-height: 400px) {
  .view_admin__clickable:hover {
    background-color: rgb(255, 253, 220);
  }
}
.view_admin__stats {
  margin-bottom: 1rem;
  padding-left: 5rem;
}
.view_admin__stats b {
  font-size: 1.2rem;
}
.view_admin__tablecontainer {
  overflow-x: scroll;
}
.view_admin table {
  width: 90rem;
  border-collapse: collapse;
  margin-bottom: 1rem;
}
.view_admin table,
.view_admin th,
.view_admin td {
  padding: 0.3rem 0.3rem;
  border: 1px solid goldenrod;
}
.view_admin th {
  position: relative;
}
.view_admin th svg {
  position: absolute;
  top: 0.4rem;
  right: 0.2rem;
}
.view_admin__sendemail {
  min-width: 2rem;
}
.view_admin__namne {
  min-width: 12rem;
}
.view_admin__email {
  min-width: 13rem;
}
.view_admin__phone {
  min-width: 8rem;
}
.view_admin__party {
  width: 3.4rem;
  padding-right: 1.4rem !important;
  padding-left: 0.4rem !important;
}
.view_admin__speach {
  width: 3.4rem;
  padding-right: 1.4rem !important;
  padding-left: 0.4rem !important;
}
.view_admin__food {
  width: 6.2rem;
  padding-right: 1.4rem !important;
  padding-left: 0.4rem !important;
}
.view_admin__added {
  min-width: 9rem;
}
.view_admin__code {
  min-width: 7rem;
}
.view_admin__msg {
  min-width: 35rem;
}
.view_admin table > thead > tr {
  background-color: rgb(245, 223, 102);
  text-align: left;
}
.view_admin table > tbody > tr:nth-child(even) {
  background-color: rgb(230, 230, 230);
}
.view_admin table > tbody > tr > td:nth-child(1),
.view_admin table > tbody > tr > td:nth-child(5),
.view_admin table > tbody > tr > td:nth-child(6),
.view_admin table > tbody > tr > td:nth-child(7) {
  text-align: center;
}

.view_road {
  padding-bottom: 3rem;
}
.view_road ul,
.view_road li {
  text-align: left;
  margin-left: 1.1rem;
  margin-right: 0.5rem;
}
@media (min-width: 850px) and (min-height: 400px) {
  .view_road ul,
  .view_road li {
    font-size: 1.1rem;
    margin-left: 3rem;
  }
}
.view_road ul {
  list-style: none;
}
.view_road ul li::before {
  content: "•"; /* Add content: \2022 is the CSS Code/unicode for a bullet */
  color: var(--main-color); /* Change the color */
  font-weight: bold; /* If you want it to be bold */
  font-size: 1.3rem;
  display: inline-block; /* Needed to add space between the bullet and the text */
  width: 1rem; /* Also needed for space (tweak if needed) */
  margin-left: -1rem; /* Also needed for space (tweak if needed) */
}
.view_road__imgdiv {
  position: relative;
  display: inline-block;
  padding: 0 1rem;
}
.view_road__imgdiv:hover {
  opacity: 0.7;
  cursor: pointer;
}
.view_road__imgdiv img {
  width: 100%;
  max-width: 35rem;
  border: 0.15rem solid var(--main-color);
}
.view_road__imgdiv p {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.925);
  bottom: 0.7rem;
  right: 1.4rem;
  color: black;
  padding: 0 0.3rem;
  font-weight: bold;
  font-size: 0.9rem;
}

.view_welcome p {
  max-width: 43rem;
  margin: 0 auto;
}
.view_welcome__menuinfo {
  display: block;
  margin: 1rem 0;
}
@media (min-width: 850px) and (min-height: 400px) {
  .view_welcome__menuinfo {
    display: none;
  }
}

.view_info b {
  font-size: 1.1rem;
}
.view_info__important {
  font-weight: normal;
  background-color: rgba(204, 174, 5, 0.418);
  margin: 1rem;
  padding: 0.75rem 0;
}
.view_info__important__br {
  height: 0.4rem;
}

.view_acco b {
  font-size: 1.2rem;
}

.view_married h2 {
  margin: 2rem 0 1rem 0;
}
.view_married p {
  max-width: 43rem;
  margin: 0 auto;
  padding: 0.5rem 1rem;
}
.view_married__menuinfo {
  display: block;
  margin: 1rem 0;
}
@media (min-width: 850px) and (min-height: 400px) {
  .view_married__menuinfo {
    display: none;
  }
}
.view_married .img_container_mar {
  border-radius: 0.5rem;
  margin: 1rem auto;
  width: calc(100% - 3rem);
  max-width: 20rem;
  height: 30rem;
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: 0rem 0rem 1rem 0rem rgb(100, 100, 100);
}
@media screen and (min-width: 470px) {
  .view_married .img_container_mar {
    max-width: 30rem;
    height: 38rem;
  }
}
@media screen and (min-width: 600px) {
  .view_married .img_container_mar {
    max-width: 33rem;
    height: 50rem;
  }
  .view_married .img_container_mar p {
    font-size: 1.2rem;
  }
}

.img_container {
  border-radius: 0.5rem;
  margin: 1.3rem 1.5rem 0.7rem 1.5rem;
  width: calc(100% - 3rem);
  height: 10rem;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: 0rem 0rem 0.75rem 0rem rgb(156, 156, 156);
}
@media (min-width: 550px) and (min-height: 400px) {
  .img_container {
    height: 13rem;
  }
}
@media (min-width: 750px) and (min-height: 520px) {
  .img_container {
    height: 16rem;
  }
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */ /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */ /* Internet Explorer/Edge */
  user-select: none;
}

.borderbranch {
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--background-color);
}

.imgbranch1,
.imgbranch2 {
  position: absolute;
  height: 15rem;
}

.imgbranch1 {
  top: -1rem;
  left: 1rem;
  transform: rotate(210deg);
}

.imgbranch2 {
  bottom: -1.5rem;
  right: 1rem;
  transform: rotate(30deg);
}

.border1 {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: 0.15rem solid var(--main-color);
  margin: 0.75rem;
}
.border1:before, .border1:after {
  content: "";
  position: absolute;
  transform: rotate(-45deg);
  background-color: var(--background-color);
  width: 6.5rem;
  height: 6.65rem;
}
.border1:before {
  right: 0;
  margin: -3.38rem;
  border-left: 0.15rem solid var(--main-color);
}
.border1:after {
  bottom: 0;
  margin: -3.38rem;
  border-right: 0.15rem solid var(--main-color);
}/*# sourceMappingURL=main.css.map */