.comp_hamburger {
  @include pc {
    display: none;
  }
  &__lines {
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    position: absolute;
    z-index: 11;
    top: 0;
    right: 0;
    transition: margin 230ms ease;
    padding: 1.25rem 0.75rem 1rem 1rem;
  }
  &__line1,
  &__line2,
  &__line3 {
    transition: opacity 1ms, background-color 300ms, transform 300ms, margin 300ms;
    width: 2.3rem;
    height: 0.25rem;
    border-radius: 1rem;
    background-color: var(--hamburger-color);
  }

  &__line2 {
    margin: 0.32rem 0;
  }
}

.comp_nav {
  @include pc {
    display: none;
  }
  position: fixed;
  z-index: 10;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgb(24 94 14 / 94%);
  // background-color: rgba(134, 115, 7, 0.94);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  transition: opacity 200ms;

  &__inner {
    a {
      -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    }
    a li {
      -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
      list-style: none;
      color: white;
      font-size: 1.4rem;
      letter-spacing: 0.07rem;
      padding: 0.5rem 0;
      margin-bottom: 1rem;
      @media (max-height: 400px) {
        margin-bottom: 0rem;
      }
      // &:not(:last-of-type) {
      //   margin-bottom: 2rem;
      // }
    }
  }
}

.comp_navpc {
  display: none;
  @include pc {
    display: block;
  }
  margin-left: 4rem;
  position: absolute;
  top: 1.6rem;
  right: 6rem;
  &__inner {
    a {
      -webkit-tap-highlight-color: rgba(131, 30, 30, 0);
    }
    a li {
      display: inline-block;
      // font-weight: bold;
      margin: 0.15rem 1.5rem;
      -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
      list-style: none;
      color: rgb(187, 160, 6);
      font-size: 1rem;
      letter-spacing: 0.04rem;
    }
  }
}

.comp_countdown {
  margin-top: 0.5rem;

  &__box {
    display: inline-block;
    width: 4.5rem;
  }
  &__num {
    font-size: 2rem;
  }
  &__text {
    font-size: 0.8rem;
  }
}

.comp_regform {
  --input-bg: rgba(211, 211, 211, 0.432);
  --border-bg: rgba(141, 141, 141, 0.877);
  max-width: 39rem;
  margin: 2rem auto;
  padding: 0 0.5rem 1rem 0.5rem;
  background-color: rgba(255, 255, 255, 0.842);
  border-radius: 0.6rem;
  box-shadow: 0 0 0.5rem 0.2rem rgb(228, 228, 228);
  transition: max-height 700ms ease-in;
  overflow: hidden;

  > p {
    padding-top: 0.7rem;
    padding-bottom: 0.5rem;
  }
  @include pc {
    label {
      font-size: 1.1rem;
    }
  }

  &__fbox {
    display: inline-block;
    margin: 0.3rem 0.5rem;
    max-width: 16.5rem;
    width: 100%;
    @media (max-width: 650px) {
      width: 100%;
      max-width: 22rem;
      margin: 0.3rem 0;
      padding: 0 0.5rem;
    }
    text-align: left;
  }

  input[type="text"],
  input[type="email"],
  input[type="tel"] {
    width: 100%;
    margin-top: 0.1rem;
    padding: 0.6rem;
    border: none;
    outline: none;
    background-color: var(--input-bg);
    &:focus-visible {
      padding: 0.45rem 0.45rem;
      border: 0.15rem solid var(--border-bg);
    }
  }

  &__fdivider {
    height: 0.15rem;
    margin: 0.5rem 0;
    background-color: rgb(225, 225, 225);
  }

  &__fbox_choice {
    display: inline-block;
    > p {
      padding: 0.3rem 0;
    }
  }
  &__flabel-choice {
    display: inline-block;
    margin: 0 0.7rem 0.2rem 0.7rem;
    padding: 0 1rem;

    input {
      margin-right: 0.3rem;
    }
  }

  &__fbox-textarea {
    display: inline-block;
    margin: 0 auto;
    max-width: 30rem;
    width: 87%;
    margin-top: 0.3rem;

    textarea {
      display: block;
      margin-top: 0.3rem;
      height: 6rem;
      width: 100%;
      padding: 0.3rem;
      resize: none;
      outline: none;
      border: none;
      font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
      background-color: var(--input-bg);
      &:focus-visible {
        border: 0.15rem solid var(--border-bg);
        padding: 0.2rem;
      }
    }
  }
}

.comp_presents {
  text-align: left;
  padding: 0 1rem;
  h3 {
    padding: 0;
    font-size: 1.1rem;
  }
  p {
    padding: 0;
  }

  &__item {
    margin-bottom: 2rem;
    padding: 0.5rem 0 0.5rem 0.5rem;
    background-color: white;
    box-shadow: 0 0 0.5rem rgb(180, 180, 180);
    border-radius: 0.5rem;

    &__img {
      text-align: center;
      margin: 1rem 0 0.5rem 0;
      img {
        max-height: 11rem;
        @media (max-width: 400px) {
          max-width: 9rem;
        }
      }
    }

    &__link {
      display: inline-block;
      margin-top: 0.6rem;
      padding: 0.2rem 0.4rem;
      background-color: rgb(238, 238, 238);
      border-radius: 0.2rem;
      font-size: 0.9rem;
      a {
        color: rgb(1, 19, 177);
      }
    }

    button {
      width: 15rem;
      width: calc(100% - 0.5rem);
      margin-top: 0.8rem;
      margin-bottom: 0.3rem;
      background-color: white;
      padding: 0.4rem 0;
      border-radius: 0.6rem;
      color: var(--hamburger-color);
      border: 1px solid var(--hamburger-color);
      &:hover {
        cursor: pointer;
        background-color: var(--hamburger-color);
        color: white;
      }
    }
  }

  // MODAL TO RESERVE - - - - - - - - - - - - - -
  &__modal {
    position: fixed;
    z-index: 13;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    background-color: rgba(0, 0, 0, 0.7);

    &_content {
      position: fixed;
      z-index: 14;
      top: 0;
      right: 0;
      left: 0;

      width: calc(100% - 2rem);
      max-width: 40rem;

      margin: calc(40vh - 2.5rem) auto 0 auto;
      padding: 1rem;

      background-color: white;

      h2 {
        margin-bottom: 0.1rem;
        font-size: 1rem;
        @include pc {
          font-size: 1.3rem;
        }
      }

      input {
        margin-top: 0.5rem;
        padding: 0.3rem 0.5rem;
        border-radius: 0.3rem;
        border: 1px solid gray;
      }

      &__btnreserve {
        margin: 1rem 0;
        background-color: var(--main-color);
        color: white;
        &:disabled {
          background-color: rgb(204, 204, 204);
          color: rgb(243, 243, 243);
        }
      }

      &__btncancle {
        background-color: rgb(204, 109, 109);
        color: white;
      }

      button {
        display: block;
        font-weight: bold;
        border: none;
        padding: 0.3rem 0;
        width: 7.5rem;
        border-radius: 0.4rem;
        &:hover:not(:disabled) {
          cursor: pointer;
        }
      }
    }
  }

  // EDIT ECTION - - - - - - - - - - - - - -
  &__editbtn {
    margin: 1rem 0.6rem 2rem 0.6rem;
    padding: 0.3rem 0.5rem;
    color: var(--hamburger-color);
    font-weight: bold;
    background-color: white;
    border: 1px solid var(--hamburger-color);
    border-radius: 0.5rem;
    &:hover {
      cursor: pointer;
      @include pc {
        color: white;
        background-color: var(--hamburger-color);
      }
    }
  }
  &__edit {
    // margin-bottom: 5rem;
    padding-bottom: 1rem;
    border: 1px solid var(--hamburger-color);
    border-radius: 1rem;
    @include pc {
      border: none;
    }
    margin: 1rem 1rem 1rem 1rem;
    input {
      padding: 0.2rem 0.4rem;
      margin-top: 0.3rem;
      margin-bottom: 0.4rem;
    }
    h2 {
      font-size: 1.3rem;
      @include pc {
        font-size: 1.4rem;
      }
      margin-bottom: 1.5rem;
    }
    div {
      margin: 0.7rem auto;
      & > span {
        font-weight: bold;
      }
      & > button {
        margin-left: 1rem;
        background-color: rgb(247, 181, 58);
        border: none;
        padding: 0.3rem 0.5rem;
        border-radius: 0.5rem;
        &:hover {
          cursor: pointer;
          color: white;
        }
      }
    }
  }
}
