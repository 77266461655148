* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --main-color: rgb(204, 174, 5);
  --hamburger-color: #739471;
  --background-color: rgb(255, 255, 255);
}

@mixin touchscreens {
  @media (hover: none) and (pointer: coarse) {
    @content;
  }
}

@mixin pc {
  @media (min-width: 850px) and (min-height: 400px) {
    @content;
  }
}

html {
  scroll-behavior: smooth;
  min-height: 100vh;
  @include pc {
    padding-top: 2rem;
  }
}

body {
  font-family: "Cormorant";
  // position: relative;
  overflow-x: clip;
  background-color: var(--background-color);
  @include pc {
    background-position: 50% 50%;
    background-image: url("../images/pc-bg.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
  }
}

#root {
  position: relative;
  max-width: 50rem;
  margin: auto;
}

a {
  text-decoration: none;
}

main {
  padding: 6rem 0.5rem 5.5rem 0.5rem;
  position: relative;
  text-align: center;
  z-index: 9;

  p {
    padding: 0.75rem 1rem;
    line-height: 1.5rem;
    font-size: 1rem;
  }
  h1 {
    color: rgb(54, 54, 54);
    letter-spacing: 0.15rem;
    font-size: 1.9rem;
  }
  h2 {
    font-size: 1.4rem;
  }
  h3 {
    padding-top: 1.2rem;
  }

  @media (max-width: 849px) and (min-height: 400px) {
    min-height: 90vh;
  }
  @include pc {
    min-height: 40rem;
    box-shadow: 0rem 0rem 2rem 0rem rgb(92, 92, 92);
    margin-bottom: 2rem;
    padding-bottom: 4rem;
    p {
      font-size: 1.1rem;
    }
    h1 {
      font-size: 2.1rem;
    }
    h2 {
      font-size: 1.5rem;
    }
    h3 {
      font-size: 1.2rem;
    }
  }
}

@import "components";
@import "utils";
@import "view";

.img_container {
  border-radius: 0.5rem;
  margin: 1.3rem 1.5rem 0.7rem 1.5rem;
  width: calc(100% - (1.5rem * 2));
  height: 10rem;
  @media (min-width: 550px) and (min-height: 400px) {
    height: 13rem;
  }
  @media (min-width: 750px) and (min-height: 520px) {
    height: 16rem;
  }
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: 0rem 0rem 0.75rem 0rem rgb(156, 156, 156);
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

.borderbranch {
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--background-color);
}

.imgbranch1,
.imgbranch2 {
  position: absolute;
  height: 15rem;
  // z-index: 10;
}
.imgbranch1 {
  top: -1rem;
  left: 1rem;
  transform: rotate(210deg);
}
.imgbranch2 {
  bottom: -1.5rem;
  right: 1rem;
  transform: rotate(30deg);
}

.border1 {
  // z-index: 10;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: 0.15rem solid var(--main-color);
  margin: 0.75rem;

  &:before,
  &:after {
    content: "";
    position: absolute;
    transform: rotate(-45deg);
    background-color: var(--background-color);
    width: 6.5rem;
    height: 6.65rem;
  }

  &:before {
    right: 0;
    margin: -3.38rem;
    border-left: 0.15rem solid var(--main-color);
  }

  &:after {
    bottom: 0;
    margin: -3.38rem;
    border-right: 0.15rem solid var(--main-color);
  }
}
